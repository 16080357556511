<template>
  <div>
    <v-data-table
      dense
      :items="teams"
      :headers="headers"
      :loading="division.loading"
      :options.sync="page"
      :search="search"
      class="elevation-1"
      hide-default-footer
      :mobile-breakpoint="0"
      loading-text="Loading... Please wait"
    >
      <template v-slot:item="{ item }">
          <tr
            :key="item.id"
            @click="selectTeam(item)"
            :active="selectedTeam.id === item.id"
          >
            <td class="text-center" :class="(item.isDeleted ? 'deleted' : '')">{{item.seed}}</td>
            <td :class="item.isDeleted ? 'deleted' : ''" v-if="($vuetify.breakpoint.xs  && division.numOfPlayers === 2 && !division.useTeamNames) || tournament.showRatings.length > 0 || tournament.isShowcase">
              <div class="noWrap" v-for="player in item.players" :key="player.id">{{player.name}}{{division.showCommit && player.commitAbbr ? ` (${player.commitAbbr})` : ''}}</div>
            </td>
            <td :class="item.isDeleted ? 'deleted' : ''" v-else>
              {{item.name}}
            </td>
            <td v-if="showCaptains">
              {{item.captains.map(m => m.name).join(' | ')}}
            </td>
            <td :class="item.isDeleted ? 'deleted' : ''" v-if="showPlayers">
              <div
                class="noWrap"
                v-for="player in item.players"
                :key="player.id"
              >
                {{player.name}}{{division.showCommit && player.commitAbbr ? ` (${player.commitAbbr})` : ''}}{{division.isNcaa ? ` ${player.movr}` : ''}}
              </div>
            </td>
            <td class="text-center" v-for="p in tournament.showRatings" :key="`rs-${p.id}`">
              <div v-if="false">{{item.teamRatings[`${p.id}`]}}</div>
              <div class="noWrap" v-for="player in item.players" :key="player.id">{{player.getRating(p.id).currentRating}}</div>
            </td>
            <td class="text-center" v-for="p in tournament.pointSystems" :key="`ps-${p.id}`">
              {{item.teamPoints[p.id] | intelliFormat}} <v-btn x-small text icon v-if="item.pointsLocked"><v-icon>fas fa-lock</v-icon></v-btn>
            </td>
            <td
              v-for="column in columns.filter(f => f.value !== 'captain')"
              :key="column.value"
              :class="item.isDeleted ? 'deleted' : ''"

            >
              <div v-if="column.value === 'rosterStatus'" :class="item.players.length >= division.numOfPlayers ? 'success--text' : 'error--text'">{{item.players.length}} of {{division.numOfPlayers}} players</div>
              <template v-else>
                <template v-if="column.perPlayer && (($vuetify.breakpoint.xs  && division.numOfPlayers === 2 && !division.useTeamNames) || tournament.showRatings.length > 0 || tournament.isShowcase)">
                  <div class="noWrap" v-for="player in item.players" :key="player.id" style="min-height: 20px">{{player[column.value]}}</div>
                </template>
                <span v-else-if="column.value === 'unix'">{{item[column.value] | unixToLocalDt}}</span>
                <span v-else>{{item[column.value] | intelliFormat}} <v-icon class="clickable" x-small v-if="column.isPoints && item.pointsLocked">{{ item.pointsIssue ? 'fas fa-exclamation fa-fw' : 'fas fa-lock fa-fw'}}</v-icon></span>
              </template>
            </td>
            <td class="text-right">
              <v-tooltip left>
                <span>Manage Team</span>
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    color="color3 color3Text--text"
                    small icon
                    :to="{name: 'division-team', params: {teamId: item.id}}"
                  >
                    <v-icon small>fas fa-caret-right</v-icon>
                  </v-btn>
                </template>
              </v-tooltip>
            </td>
          </tr>
      </template>
    </v-data-table>
    <v-dialog
      v-model="dialog"
      scrollable
      max-width="500px"
      transition="dialog-transition"
    >
      <v-card color="color2">
        <v-toolbar color="color1 color1Text--text">
          <v-text-field
            v-if="editName"
            solo
            label="Team Name"
            v-model="teamName"
            hide-details
          ></v-text-field>
          <v-toolbar-title v-else>{{selectedTeam.name}}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-fab-transition>
            <v-btn
              fab
              class="mr-1"
              color="success white--text"
              small
              @click.stop="save"
              :loading="saving"
              v-if="editName && teamName !== selectedTeam.name">
              <v-icon small>fas fa-save</v-icon>
            </v-btn>
          </v-fab-transition>
          <v-fab-transition>
            <v-btn
              fab
              :color="activeIcon.color"
              small
              @click.stop="activeIcon.onClick"
              :key="activeIcon.icon"
              :disabled="saving"
            >
              <v-icon small>{{activeIcon.icon}}</v-icon>
            </v-btn>
          </v-fab-transition>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row dense>
              <v-col cols="12" v-if="selectedTeam.pointsIssue">
                <v-card class="pa-3">
                  <v-alert type="warning" :value="true" prominent text>
                    Points Issue
                  </v-alert>
                  This usually occures when a player is changed after the team is seeded.
                </v-card>
              </v-col>
              <v-col cols="12" v-for="player in selectedTeam.players" :key="player.id">
                <team-player-card
                  :key="`${player.id}-${dialog}`"
                  :player="player"
                  :tournament="tournament"
                ></team-player-card>
              </v-col>
            </v-row>
            <v-row dense v-if="division.teamType && user">
              <v-col cols="12">
                <v-window v-model="tWindow">
                  <v-window-item :key="0">
                    <v-btn color="color3 color3Text--text" x-small @click.stop="tWindow = 1">Update Team</v-btn>
                  </v-window-item>
                  <v-window-item :key="1">
                    <team-lookup
                      v-if="tWindow === 1"
                      :type="division.teamType"
                      @team-selected="onTeamSelected"
                    ></team-lookup>
                    <div class="text-end">
                      <v-btn
                        color="error white--text"
                        small fab
                        @click.stop="tWindow = 0"
                      >
                        <v-icon>fas fa-times</v-icon>
                      </v-btn>
                    </div>
                  </v-window-item>
                  <v-window-item :key="2">
                    Assign the parent team as {{newParent && newParent.name}}?
                    <search-result-item
                      :team="newParent"
                    ></search-result-item>
                    <div class="text-end">
                      <v-btn
                        color="success white--text"
                        small fab class="mr-2"
                        :loading="teamSave"
                        @click.stop="saveTeam"
                      >
                        <v-icon>fas fa-save</v-icon>
                      </v-btn>
                      <v-btn
                        color="error white--text"
                        small fab
                        :disabled="teamSave"
                        @click.stop="tWindow = 1"
                      >
                        <v-icon>fas fa-times</v-icon>
                      </v-btn>
                    </div>
                  </v-window-item>
                </v-window>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import firstBy from 'thenby'
import { mapGetters } from 'vuex'
import { ageDivision } from '@/classes/ProfileHelpers'
const TeamPlayerCard = () => import('@/components/Tournament/Division/Teams/TeamPlayerCard.vue')
const TeamLookup = () => import('@/components/Team/Rostered/Search/TeamLookup.vue')
const SearchResultItem = () => import('@/components/Team/Rostered/Search/SearchResultItem.vue')

export default {
  props: ['division', 'showDeleted', 'search', 'columns', 'showPlayers'],
  data () {
    return {
      page: { itemsPerPage: -1, sortBy: ['iSeed'] },
      dialog: false,
      selectedTeam: {
        id: 0,
        name: '',
        players: []
      },
      editName: false,
      teamName: null,
      saving: false,
      tWindow: null,
      newParent: null,
      teamSave: false
    }
  },
  computed: {
    ...mapGetters(['tournament', 'user']),
    activeIcon () {
      return this.editName ? {
        icon: 'fas fa-times',
        onClick: () => { this.editName = false },
        color: 'error white--text'
      } : {
        icon: 'fas fa-pen',
        onClick: () => { this.editName = true },
        color: 'color3Text color3--text'
      }
    },
    teams () {
      const teams = this.division.activeTeams
      if (this.tournament.pointSystems.length) {
        const id = this.tournament.pointSystems[0].id
        return teams.sort(
          firstBy('finish')
            .thenBy('iSeed')
            .thenBy(function (v1, v2) { return v1.teamPoints[id] - v2.teamPoints[id] }, -1)
            .thenBy('unix')
        )
      }

      if (this.division.isUSAV || this.division.isAVP) {
        return teams.sort(
          firstBy('finish')
            .thenBy('iSeed')
            .thenBy(function (a, b) { return b.usavPoints - a.usavPoints })
            .thenBy(function (a, b) { return b.aauPoints - a.aauPoints })
            .thenBy(function (a, b) { return b.p1440Points - a.p1440Points })
            .thenBy('unix')
        )
      }
      if (this.division.isAAU) {
        return teams.sort(
          firstBy('finish')
            .thenBy('iSeed')
            .thenBy(function (a, b) { return b.aauPoints - a.aauPoints })
            .thenBy(function (a, b) { return b.p1440Points - a.p1440Points })
            .thenBy(function (a, b) { return b.usavPoints - a.usavPoints })
            .thenBy('unix')
        )
      }
      if (this.division.isBVNE) {
        return teams.sort(
          firstBy('finish')
            .thenBy('iSeed')
            .thenBy(function (a, b) { return b.bvnePoints - a.bvnePoints })
            .thenBy(function (a, b) { return b.aauPoints - a.aauPoints })
            .thenBy(function (a, b) { return b.p1440Points - a.p1440Points })
            .thenBy(function (a, b) { return b.usavPoints - a.usavPoints })
            .thenBy('unix')
        )
      }
      if (this.division.isP1440) {
        return teams.sort(
          firstBy('finish')
            .thenBy('iSeed')
            .thenBy(function (a, b) { return b.p1440Points - a.p1440Points })
            .thenBy(function (a, b) { return b.aauPoints - a.aauPoints })
            .thenBy(function (a, b) { return b.bvnePoints - a.bvnePoints })
            .thenBy(function (a, b) { return b.usavPoints - a.usavPoints })
            .thenBy('unix')
        )
      }
      if (this.division.isNcaa) {
        return teams.sort(
          firstBy('finish')
            .thenBy('iSeed')
            .thenBy('movr', { direction: -1 })
            .thenBy('name', { direction: -1 })
            .thenBy('unix')
        )
      }
      return teams.sort(
        firstBy('finish')
          .thenBy('iSeed')
          .thenBy(function (a, b) { return b.usavPoints - a.usavPoints })
          .thenBy(function (a, b) { return b.aauPoints - a.aauPoints })
          .thenBy(function (a, b) { return b.p1440Points - a.p1440Points })
          .thenBy('unix')
      )
    },
    showCaptains () {
      return this.columns.find(f => f.value === 'captain')
    },
    headers () {
      const headers = [
        { text: 'Seed', value: 'seed', align: 'center', sortable: true },
        { text: 'Name', value: 'name', align: 'left', sortable: true }
      ]
      if (this.showCaptains) {
        headers.push({ text: 'Captain', value: 'captain', align: 'left', sortable: true })
      }
      if (this.showPlayers) {
        headers.push({ text: 'Players', value: 'players', align: 'left', sortable: false })
      }
      this.tournament.showRatings.forEach(p => {
        headers.push({ text: `${p.name} Ratings`, value: `teamRatings.${p.id}`, align: 'center', sortable: true })
      })
      this.tournament.pointSystems.forEach(p => {
        headers.push({ text: `${p.name} Points`, value: `teamPoints.${p.id}`, align: 'center', sortable: true })
      })
      this.columns.filter(f => f.value !== 'captain').forEach(c => {
        headers.push({ text: c.header, value: c.value, align: 'left', sortable: true })
      })
      headers.push({ text: '', align: 'right', sortable: false })
      return headers
    }
  },
  methods: {
    ad (dob, gradYear) {
      return ageDivision(dob, gradYear)
    },
    selectTeam (team) {
      this.editName = false
      this.teamName = team.name
      this.selectedTeam = team
      this.dialog = true
    },
    save () {
      this.saving = true
      this.$VBL.team.updateName({
        id: this.selectedTeam.id,
        name: this.teamName
      })
        .catch(e => { console.log(e.response) })
        .finally(() => {
          this.saving = false
          this.editName = false
        })
    },
    onTeamSelected (team) {
      this.newParent = team
      this.tWindow = 2
    },
    saveTeam () {
      this.teamSave = true
      this.$VBL.roster.connect(this.selectedTeam.id, this.newParent.id)
        .then(r => {
          this.tWindow = 0
        })
        .catch(e => console.log(e.response))
        .finally(() => {
          this.teamSave = false
        })
    }
  },
  components: {
    TeamPlayerCard,
    TeamLookup,
    SearchResultItem
  },
  mounted () {
    // this.page.sortBy = this.division.teamSort
    // this.page.descending = this.division.teamSort.includes('Points')
  }
}
</script>

<style scoped>
.noWrap {
  white-space: nowrap;
}
.deleted {
  text-decoration: line-through
}
</style>
